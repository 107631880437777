import Vue from 'vue'
import VueRouter from 'vue-router'
import About from '../pages/about/index.vue'
import Home from '../pages/home/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About | Famydell',
    component: About
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    } else {
      return {
        x: 0, y: 0,
        behavior: 'smooth'
      }
    }
  }
})
export default router
