import linkedin from '@iconify/icons-typcn/social-linkedin';
import facebook from '@iconify/icons-typcn/social-facebook';
import instagram from '@iconify/icons-typcn/social-instagram';
import twitter from '@iconify/icons-foundation/social-twitter';

export default {
  name: 'Footer',
  components: {},
  props: [],
  data() {
    return {
      icons: {
        linkedin,
        instagram,
        facebook,
        twitter,
      }

    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {

  }
}


