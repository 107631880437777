import Header from "./components/header/index.vue";
import Footer from "./components/footer/index.vue";
import Navigation from "./components/navigation/index.vue";

export default {
    name: 'App',
    components: {
        Header,
        Footer,
        Navigation
    },
    props: [],
    data() {
        return {
            isNavigationOpen: false
        }
    },
    computed: {

    },
    mounted() {

    },
    methods: {
        toggleNavigation() {
            this.isNavigationOpen = !this.isNavigationOpen;
        }
    }
}


