import hamburgerMenu from '@iconify/icons-cil/hamburger-menu';

export default {
  name: 'Header',
  components: {},
  props: [],
  data() {
    return {
      icons: {
        hamburgerMenu
      },
      scrollPosition: null
    }
  },
  computed: {
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.pageYOffset;
    },
    openNavigation() {
      this.$emit('open-navigation')
    },
    scrollInHome() {
      if (this.$route.name != "Home") {
        this.$router.push('/')
      }
    }
  }
}


