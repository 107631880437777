import chevronLeft from "@iconify/icons-bi/chevron-left";
import chevronRight from "@iconify/icons-bi/chevron-right";
import packageLight from "@iconify/icons-ph/package-light";
import analyticsOutline from "@iconify/icons-ion/analytics-outline";
import engineeringIcon from "@iconify/icons-flat-color-icons/engineering";
import buildingConstruction from "@iconify/icons-emojione/building-construction";

import "@splidejs/splide/dist/css/themes/splide-default.min.css";

import slide1 from "../../assets/famydell-philosophy1.png";
import slide2 from "../../assets/famydell-philosophy2.png";
import slide3 from "../../assets/famydell-philosophy3.png";
import slide4 from "../../assets/famydell-philosophy4.png";
import mobileSlide1 from "../../assets/famydell-philosophy-mobile1.png";
import mobileSlide2 from "../../assets/famydell-philosophy-mobile2.png";
import mobileSlide3 from "../../assets/famydell-philosophy-mobile3.png";
import mobileSlide4 from "../../assets/famydell-philosophy-mobile4.png";

import procurement from "../../assets/famydell-services-md.webp";
import data from "../../assets/data.png";
import construction from "../../assets/construction.png";
import engineering from "../../assets/engineering.png";

export default {
  name: "Home",
  components: {},
  props: [],
  data() {
    return {
      icons: {
        chevronLeft,
        chevronRight,
        packageLight,
        engineeringIcon,
        analyticsOutline,
        buildingConstruction,
      },
      mobileOptions: {
        autoplay: true,
        rewind: true,
        type: "loop",
        classes: {
          pagination: "splide__pagination pagination-container",
          page: "splide__pagination__page pagination-page",
        },
        width: 250,
        perPage: 1,
        gap: "1.5rem",
        pauseOnFocus: true,
        interval: 3000,
        arrows: false,
        lazyLoad: true,
      },
      desktopOptions: {
        autoplay: true,
        rewind: true,
        type: "loop",
        classes: {
          pagination: "splide__pagination pagination-container",
          page: "splide__pagination__page pagination-page",
        },
        width: 500,
        perPage: 1,
        gap: "1rem",
        pauseOnFocus: true,
        interval: 3000,
        arrows: false,
        lazyLoad: true,
      },
      mobileSlides: [
        { src: mobileSlide1, alt: "Innovation Pioneer" },
        { src: mobileSlide2, alt: "Collaboration Partners" },
        { src: mobileSlide3, alt: "Excellence Driven" },
        { src: mobileSlide4, alt: "Community Engagement & CSR" },
      ],
      desktopSlides: [
        { src: slide1, alt: "Innovation Pioneer" },
        { src: slide2, alt: "Collaboration Partners" },
        { src: slide3, alt: "Excellence Driven" },
        { src: slide4, alt: "Community Engagement & CSR" },
      ],
      services: [
        {
          id: 1,
          title: "Enginnering & Asset Integrity",
          image: engineering,
          body:
            "From conceptual design through systems commissioning, Famydell provides expertise in electrical system design for projects of all ranges. Our engineers produce innovative designs that reflect concerns for energy conservation, maintenance efficiency, and environmental responsibility. From lighting to power distribution to data centers to asset integrity, Famydell is well versed in the latest techniques, equipment and methodologies, as well as electrical codes and systems.",
        },
        {
          id: 2,
          title: "Construction & Installation",
          image: construction,
          body:
            "Famydell has one of the most experienced Subsurface teams in West Africa with a proven track record. We employ highly experienced subsurface professionals with expertise in geology, geophysics, reservoir engineering and full field development, delivering best in technical solutions as part of large-scale integrated development or smaller stand-alone projects across the world.",
        },
        {
          id: 3,
          title: "Strategic Procurement",
          image: procurement,
          body:
            "We offer Onshore and Offshore strategic procurement services. Industrial services such as: Scaffolding, Painting, Insulation, Fire Proofing. In steel; Rope Access (All trades, inclusive of electrical and steel installation). Product Lines Pumps and rotating equipment, Casings, Tubings, drill pipes, well heads, skid mounted fabricated packages, Power Turbines, Umbilicals, Mooring systems and Ropes",
        },
        {
          id: 4,
          title: "Data Analytics",
          image: data,
          body:
            "Data runs the world and to be at par with the latest trends in your industry, you need your data to work for you. We provide consulting and support on data management, data visualizations, and data analysis to ensure you get the maximum value from your data in a way that’s easy to understand and interpret.",
        },
      ],
      inViewService: null,
      counter: 0
    };
  },
  computed: {},
  mounted() {},
  created() {
    document.title = 'Famydell'
    this.selectService(1);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },
  methods: {
    selectService(id) {
      id >= 1 && id <= 4 ? (this.inViewService = this.services[id - 1]) : null;
    },
  },
};
