export default {
  name: 'Navigation',
  components: {},
  props: {
    isNavigationOpen: Boolean
  },
  data() {
    return {
    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    closeNavigation() {
      this.$emit('close-navigation')
    }

  }
}


