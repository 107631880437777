import Vue from 'vue';
import App from './App.vue';
import router from './router';
import IconifyIcon from '@iconify/vue';
import VueSplide from '@splidejs/vue-splide';

Vue.config.productionTip = false

Vue.use(IconifyIcon);
Vue.use(VueSplide);

new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
