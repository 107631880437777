export default {
  name: 'about',
  components: {},
  props: [],
  data() {
    return {

    }
  },
  computed: {

  },
  created() {
    document.title = 'About | Famydell'
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  },
  methods: {

  }
}


